<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list">
        <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="uid" label="用户编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="video_id" label="视频编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fee" label="金额" show-overflow-tooltip></el-table-column>
        <el-table-column label="时间" show-overflow-tooltip>
          <template #default="s">
            {{s.row.created_at | date}}
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "video-count-down-award-log",

  data() {
    return {
      list: [],
      page: 1,
      total: 0,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.card.plugin.homePage.videoPage.videoCountDownAwardLog({
        page: this.page,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  }
}
</script>

<style scoped>

</style>